// .index-box{

// }
* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Standard syntax */
}

.app {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--black);
    padding: 0px;
    margin: 0px;
    height: 100vh;
}

.screen {
    min-width: 400px;
    width: 100%;
    max-width: 550px;
    height: inherit;
    // background-color: var(--white);
}

.card {
    padding: 10px;
    // margin: 5px;
    border-radius: 5px;
}