:root {
    /* colors */
    --red: #{$red};
    --green: #{$green};
    --blue: #{$blue};
    --white: #{$white};
    --black: #{$black};
    --black-grey: #{$blackGrey};
    --light: #{$light};
    --yellow: #{$yellow};
    /*light colors*/
    --red-light: #{$redLight};
    --green-light: #{$greenLight};
    --blue-light: #{$blueLight};
    --white-light: #{$whiteLight};
    --black-light: #{$blackLight};
    --black-grey-light: #{$blackGreyLight};
    --light-light: #{$lightLight};
    --yellow-light: #{$yellowLight};
    /* --white-grey: */
    /* font sizes and widths */
    --font-size-l: #{$fontSizeL};
    --font-weight-l: #{$fontWeightL};
    --font-size-m: #{$fontSizeM};
    --font-weight-m: #{$fontWeightM};
    --font-size-s: #{$fontSizeS};
    --font-weight-s: #{$fontWeightS};
}

// font
.font {

    // fontssizes
    &-m {
        font-size: var(--font-size-m);
        font-weight: var(--font-weight-m);
    }

    &-s {
        font-size: var(--font-size-s);
        font-weight: var(--font-weight-s);
    }

    &-l {
        font-size: var(--font-size-l);
        font-weight: var(--font-weight-l);
    }

    // font colors
    &-red {
        color: var(--red);
    }

    &-green {
        color: var(--green);
    }

    &-blue {
        color: var(--blue);
    }

    &-black {
        color: var(--black);
    }

    &-white {
        color: var(--white);
    }

    &-dark-grey {
        color: var(--black-grey);
    }
}

//border
.border-color {
    &-red {
        border-color: var(--red);
    }

    &-green {
        border-color: var(--green);
    }

    &-blue {
        border-color: var(--blue);
    }

    &-black {
        border-color: var(--black);
    }

    &-white {
        border-color: var(--white);
    }

    &-dark-grey {
        border-color: var(--black-grey);
    }

}

.border-cy {
    border-radius: 50%;
}

// Generating col classes for flexbox
$col-count: 12; // Define the number of columns you want to support

@for $i from 1 through $col-count {
    .border-#{$i} {
        border-width: $i+px;
        border-style: solid;
    }

    .border-left-#{$i} {
        border-left-width: $i+px;
        border-style: solid;
    }

    .border-right-#{$i} {
        border-right-width: $i+px;
        border-style: solid;
    }

}

$col-count: 12; // Define the number of columns you want to support

@for $i from 1 through $col-count {
    .border-radius-#{$i} {
        border-radius: $i+px;
    }
}

.border-right-none {
    border-right: none;
}

.border-left-none {
    border-left: none;
}

// background colors
.bg {
    &-light {
        background-color: var(--light);
    }

    &-white {
        background-color: var(--white);
    }

    &-blue {
        background-color: var(--blue);
    }

    &-red {
        background-color: var(--red);
    }

    &-green {
        background-color: var(--green);
    }

    &-yellow {
        background-color: var(--yellow);
    }

    &-dark-grey {
        background-color: var(--black-grey);
    }





    // light bg
    &-light-light {
        background-color: var(--light-light);
    }

    &-white-light {
        background-color: var(--white-light);
    }

    &-blue-light {
        background-color: var(--blue-light);
    }

    &-red-light {
        background-color: var(--red-light);
    }

    &-green-light {
        background-color: var(--green-light);
    }

    &-yellow-light {
        background-color: var(--yellow-light);
    }

    &-dark-grey-light {
        background-color: var(--black-grey-light);
    }
}

// buttons
.btn {
    text-align: center;
    // cursor: pointer;
    text-decoration: none;
    border: none;
    display: inline-block;
    outline: none;

    &:hover,
    &-md:hover {
        cursor: pointer;
    }

    &-md {
        padding: 10px 20px;
    }

    &-sm {
        padding: 8px 15px;
    }


    &-cy {
        border-radius: 50px;
        border: none;
    }

    &-block {
        display: block;
    }
}

// labels
.label {
    display: inline-block;
    padding: 3px 5px;
    border-radius: 3px;
    font-weight: 500;

    &-blue {
        @extend .label;
        color: var(--blue);
        background-color: hexToRgba($blue, 0.1);
    }

    &-green {
        @extend .label;
        color: var(--green);
        background-color: hexToRgba($green, 0.1);
    }

    &-red {
        @extend .label;
        color: var(--red);
        background-color: hexToRgba($red, 0.1);
    }

    &-grey {
        @extend .label;
        color: var(--black-grey);
        background-color: hexToRgba($blackGrey, 0.1);
    }

    &-yellow {
        @extend .label;
        color: var(--yellow);
        background-color: hexToRgba($yellow, 0.1);
    }


    // &-border-blue {
    //     @extend .label;
    //     color: var(--blue);
    //     border: 1px solid var(--blue);
    // }

    // &-border-green {
    //     @extend .label;
    //     color: var(--green);
    //     border: 1px solid var(--green);
    // }

    // &-border-red {
    //     @extend .label;
    //     color: var(--red);
    //     border: 1px solid var(--red);
    // }

    // &-border-grey {
    //     @extend .label;
    //     color: var(--black-grey);
    //     border: 1px solid var(--blackGrey);
    // }

    // &-border-yellow {
    //     @extend .label;
    //     color: var(--yellow);
    //     border: 1px solid var(--yellow);
    // }
}

// icons
.icon {
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &-md {
        @extend .icon;
        width: 35px;
        height: 35px;
        font-size: var(--font-size-m);
        font-weight: var(--font-weight-m);
    }

    &-green {
        color: var(--green);
        background-color: hexToRgba($green, 0.1);
    }

    &-red {
        color: var(--red);
        background-color: hexToRgba($red, 0.1);
    }

    &-grey {
        color: var(--black-grey);
        background-color: hexToRgba($blackGrey, 0.1);
    }
}

// alignments
.d-flex {
    display: flex;

    &-inline {
        display: inline-flex;
    }
}

.justify-content {
    &-center {
        justify-content: center;
    }

    &-between {
        justify-content: space-between;
    }

    &-start {
        justify-content: start;
    }

    &-end {
        justify-content: end;
    }
}

.align-items {
    &-center {
        align-items: center;
    }

    &-start {
        align-items: start;
    }

    &-end {
        align-items: end;
    }
}

.text-align {
    &-left {
        text-align: left;
    }

    &-center {
        text-align: center;
    }

    &-right {
        text-align: right;
    }
}

// Generating col classes for flexbox
$col-count: 12; // Define the number of columns you want to support

@for $i from 1 through $col-count {
    .flex-grow-#{$i} {
        flex-grow: $i;
    }

    .col-#{$i} {
        flex: 0 0 auto;
        width: percentage($i / $col-count);
        max-width: percentage($i / $col-count);
    }


}


// @for $i from 1 through $col-count {
//     .flex-grow-#{$i} {
//         flex-grow: 0 0 auto;
//     }
// }


// padding and margins
// Define padding and margin values
$spacing-values: (
    0,
    5px,
    10px,
    15px,
    20px,
    25px,
    30px
); // Add more values as needed

// Generate padding classes
@each $value in $spacing-values {
    .px-#{$value} {
        padding-left: $value;
        padding-right: $value;
    }

    .py-#{$value} {
        padding-top: $value;
        padding-bottom: $value;
    }
}

// Generate margin classes
@each $value in $spacing-values {
    .mx-#{$value} {
        margin-left: $value;
        margin-right: $value;
    }

    .my-#{$value} {
        margin-top: $value;
        margin-bottom: $value;
    }
}

// Generate specific side padding and margin classes
@each $value in $spacing-values {
    .pt-#{$value} {
        padding-top: $value;
    }

    .pr-#{$value} {
        padding-right: $value;
    }

    .pb-#{$value} {
        padding-bottom: $value;
    }

    .pl-#{$value} {
        padding-left: $value;
    }

    .mt-#{$value} {
        margin-top: $value;
    }

    .mr-#{$value} {
        margin-right: $value;
    }

    .mb-#{$value} {
        margin-bottom: $value;
    }

    .ml-#{$value} {
        margin-left: $value;
    }
}


/* alignments components */
// .btn-md {
//     @mixin btn;
// }

// padding: 5px 10px;